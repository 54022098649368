import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { AgentCookieService } from '../../agent/services/agent-cookie.service';
import { LoginApiService } from '../../login/services/login-api.service';
/**
 *Check User has role Administrator
 * */
@Injectable({ providedIn: 'root' })
export class AdminGuardService implements CanActivate {
  constructor(private router: Router, private loginApiService: LoginApiService, private agentCookieService: AgentCookieService) {

  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.loginApiService.getLoggedInUser().pipe(
      switchMap((): Observable<boolean | UrlTree> => {
        return this.agentCookieService.getAgentCookie().pipe(switchMap(cookie => {
          if (cookie.hasRole('Administrator')) {
            return of(true);
          } else {
            let tree = this.router.parseUrl('/');
            return of(tree);
          }
        }));
      }),
      catchError(err => {
        let tree = this.router.parseUrl('/login');
        return of(tree);
      })
    );
  }
}
