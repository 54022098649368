import { Injectable } from '@angular/core';
import { AgentApiService } from './agent-api.service';
import { AgentCookieService } from './agent-cookie.service';
import { Observable, concat, of, forkJoin, iif } from 'rxjs';
import { map, mergeMap, combineLatest, zip } from 'rxjs/operators';
import { Agent } from '../models/agent.model';
import { Delegator } from '../models/delegator.model';

/**
 * Provides an easy way to get a list of delegates to display as part of a dropdown list.
 * */
@Injectable({ providedIn: 'root' })
export class AgentSelectionListService {
    constructor(private agentApi: AgentApiService, private agentCookie: AgentCookieService) {

    }

    getAgentList(): Observable<Agent[]> {
        return this.agentCookie
            .getAgentCookie()
            .pipe(
                mergeMap(cookie =>
                    forkJoin(
                        of({
                            AgentId: cookie.AgentId || 0,
                            FirstName: "Me",
                            LastName: `(${cookie.FirstName} ${cookie.LastName})`,
                        } as Agent),
                        this.agentApi.getDelegatorsForAgentId(cookie.AgentId || 0)
                            .pipe(
                                map((delegators: Delegator[]) => delegators.map(d => {
                                    return {
                                        AgentId: d.DelegateAgentId,
                                        FirstName: d.DelegatorFirstName,
                                        LastName: d.DelegatorLastName,
                                    } as Agent;
                                })
                                )
                            ),
                    )
                ),
                map(([agent, delegators]) => {
                    delegators.unshift(agent);
                    return delegators;
                })
            );
    }
}
