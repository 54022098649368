import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {ServerVersionConfiguration} from '../models/server-version-configuration.model';

/**
 * Service to the Core ADX HTTP API.
 */
@Injectable({providedIn: 'root'})
export class CoreApiService {
  apiUrl = '/api';

  constructor(private http: HttpClient) {
  }

  /**
   * Returns the API configuration and version information that the front-end cares about.
   * */
  getServerVersion(): Observable<ServerVersionConfiguration> {
    return this.http.get<ServerVersionConfiguration>(`${this.apiUrl}/version`)
      .pipe(map(r => new ServerVersionConfiguration().deserialize(r)));
  }

  getSystemNotifications(): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/systemnotifications`);
  }

  getAdminMessagesByAgentId(agentId: number): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/admin-messages/by-agent-id?agentId=${agentId}`)
  }
}
