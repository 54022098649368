import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, CanActivateChild } from '@angular/router';
import { Observable } from 'rxjs';
import { AgentCookieService } from '../../agent/services/agent-cookie.service';


/**
 * 
 * Routing role guard service.
 * */

@Injectable({
    providedIn: 'root'
})
export class RoleGuardService implements CanActivate, CanActivateChild {
    constructor(private _agentCookieService: AgentCookieService, private _router: Router) { }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

        let isUserRoleAllowed = this.checkUserRole(route.data.allowedRoles || []);

        if (isUserRoleAllowed) {
            return true;
        } else {
            // redirect to non Angular route
            if (route.data.isRedirectToNonAngular) {
                window.location.href = route.data.redirectUrl || '/';
               return false;
            }
            //redirect to Angular route
            else {                
                let result = isUserRoleAllowed || this._router.parseUrl(route.data.redirectUrl || '/');
                return result;
            }
        }
    }

    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.canActivate(childRoute, state);
    }

    private checkUserRole(allowedRoles: string[]): boolean {
        let userRoles: string[];

        this._agentCookieService.getAgentCookie().subscribe((cookie) => {
            userRoles = cookie.Roles;
        });

        for (let i = 0; i < allowedRoles.length; i++) {
            if (userRoles.indexOf(allowedRoles[i]) !== -1) {
                return true;
            }
        }
        return false;
    }
}








